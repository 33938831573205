import { Action} from '@ngrx/store';
import { type } from '../../util';
import { IFormInputChangePayload } from '../abstract.components/abstract.form.component';
import { ISelectOptionModel } from '../../core/services/interfaces';
import { ValidationTypesEnum } from '../../core/services/employee/employee.service';
import { FieldsAsyncValidationEnum } from './apply-form.reducer';

export const ActionTypes = {
  CHECK_MANAGER: type('[Apply form] check manager'),
  INPUT_UPDATE: type('[Apply form] input change'),
  PHOTO_UPDATE: type('[Apply form] photo change'),
  CAPTCHA_UPDATE: type('[Apply form] captcha change'),
  SUBMIT_FORM: type('[Apply form] submit form'),
  COMPLETE_FORM: type('[Apply form] complete form'),
  SOCIAL_AUTHORIZATION: type('[Apply form] social authorization'),
  SOCIAL_COMPLETE: type('[Apply form] social authorization complete'),
  FETCH_SCHOOLS: type('[Apply form] fetch schools list'),
  SCHOOLS_LIST_UPDATE: type('[Apply form] schools list update'),
  SCHOOLS_INPUT_UPDATE: type('[Apply form] schools input update'),
  SET_FIELD_LOADING_STATE: type('[Apply form] set field loading state'),
  VALIDATE_PHONE: type('[Apply form] validate phone'),
  SET_PHONE_VALIDATION: type('[Apply form] set phone validation'),
};

export class InputUpdate implements Action {
  public type = ActionTypes.INPUT_UPDATE;

  constructor(public payload: IFormInputChangePayload) {}
}

export class CaptchaUpdate implements Action {
  public type = ActionTypes.CAPTCHA_UPDATE;

  constructor(public payload: {
    value: object,
    name: string
  }) {}
}

export class SubmitForm implements Action {
  public type = ActionTypes.SUBMIT_FORM;

  constructor(public payload: {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    photo: string | null,
    school: string,
    email: string,
    id: any,
    captcha: object
  }) {}
}

export class CompleteForm implements Action {
  public type = ActionTypes.COMPLETE_FORM;

  constructor(public payload?: any) {}
}

export class SocialAuthorization implements Action {
  public type = ActionTypes.SOCIAL_AUTHORIZATION;

  constructor(public payload: string) {}
}

export class SocialAuthorizationComplete implements Action {
  public type = ActionTypes.SOCIAL_COMPLETE;

  constructor(public payload: {
    firstName: string,
    lastName: string,
    email: string,
    photo: string
  }) {}
}

export class FetchSchoolsList implements Action {
  public type = ActionTypes.FETCH_SCHOOLS;

  constructor(public payload?: any) {}
}

export class SchoolsListUpdate implements Action {
  public type = ActionTypes.SCHOOLS_LIST_UPDATE;

  constructor(public payload: ISelectOptionModel[]) {}
}

export class SchoolInputUpdate implements Action {
  public type = ActionTypes.SCHOOLS_INPUT_UPDATE;

  constructor(public payload: string) {}
}

export class SetFieldLoadingState implements Action {
  public type = ActionTypes.SET_FIELD_LOADING_STATE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    isLoading: boolean
  }) {
  }
}

export class ValidatePhone implements Action {
  public type = ActionTypes.VALIDATE_PHONE;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    phone: string
  }) {
  }
}

export class SetPhoneValidation implements Action {
  public type = ActionTypes.SET_PHONE_VALIDATION;

  constructor(public payload: {
    field: FieldsAsyncValidationEnum,
    valid: ValidationTypesEnum
  }) {
  }
}

export type TypeAction =
  InputUpdate |
  CaptchaUpdate |
  SubmitForm |
  CompleteForm |
  SocialAuthorization |
  SocialAuthorizationComplete |
  FetchSchoolsList |
  SchoolsListUpdate |
  SchoolInputUpdate |
  SetFieldLoadingState |
  ValidatePhone |
  SetPhoneValidation;
