import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService,
  combineHeadersOptions,
  commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';
import { Observable } from 'rxjs';
import { IManagerProfileViewModel } from './interfaces';
import { AppService } from '../../../../app.service';

@Injectable()
export class ManagersService extends AbstractServerInteractionService {
  protected get url(): string {
    return this.appService.getApiUrls().managers;
  }

  constructor(protected appService: AppService,
    protected http: HttpClient) {
    super();
  }

  public getManagerById(id: string): Observable<IManagerProfileViewModel | any> {
    return this.http.get(`${this.url}/${id}`,{ headers: combineHeadersOptions(commonErrorHandlingDisabled)});
  }
}
